import React, { useState } from 'react';
import ScrollToBottom from "react-scroll-to-bottom";
import ChatQuery from './ChatQuery.js';

function ChatBot(){
    const [currentUserMessage, setCurrentUserMessage] = useState("")
    const [messageList, setMessageList] = useState([]);
    var [currentBotMessage, setCurrentBotMessage] = useState([]);

    const sendMessage = async () => {
        if (currentUserMessage !== ""){
            const userMessageData = {
                user: "you",
                message: currentUserMessage,
                time: new Date(Date.now()).toLocaleTimeString(),
            };
            setMessageList((list) => [...list, userMessageData]);
            let sendingMessage = currentUserMessage

            setCurrentUserMessage("");
            
            let response = await ChatQuery(sendingMessage)
            // eslint-disable-next-line no-undef
            let decoder = new TextDecoderStream();
            if (!response.body) return;
            const reader = response.body.pipeThrough(decoder).getReader();

            
            var tmpBotMessage = "";

            while(true){
                var {value, done} = await reader.read();
                if (done){
                    break;
                }else{
                    tmpBotMessage += value;
                    setCurrentBotMessage(tmpBotMessage);
                }
            }

            var botMessageData =  {
                user: "Devin",
                message: tmpBotMessage,
                time: new Date(Date.now()).toLocaleTimeString(),
            };
            setCurrentBotMessage("");
            setMessageList((list) => [...list, botMessageData]);
        }
    }
    return (
        <div className="chat-window">
        
    
            <div className="chat-header">
            <p>Live Chat</p>
            </div>
            <div className="chat-body">
                <ScrollToBottom className="message-container">
                    {messageList.map((messageContent, index) => {
                        return (
                            <div
                            className="message"
                            id={messageContent.user==="you" ? "you" : "bot"}
                            key={index}
                            >
                            <div>
                                <div className="message-content">
                                    <div id="just-line-break">
                                        <p>{messageContent.message} </p>
                                    </div> 
                                </div>
                                <div className="message-meta">
                                <p id="user">{messageContent.user}</p>
                                <p id="time">&nbsp;{messageContent.time}</p>
                                </div>
                            </div>
                            </div>
                        );
                    })}
                    <div className="message" id="bot">
                        <div className="message-content">
                            <div id="just-line-break"><p>{currentBotMessage}</p></div> 
                        </div>
                    </div>
                </ScrollToBottom>
            </div>
            <div className="chat-footer">
                <input 
                type="text"
                value={currentUserMessage}
                placeholder="..."
                onChange={(event) =>{
                    setCurrentUserMessage(event.target.value)
                }}
                onKeyDown={(event) => {
                    event.key === "Enter" && sendMessage();
                }}
                />
                <button onClick={sendMessage}>&#9658;</button>
            </div>
        </div>
    )
}

export default ChatBot