const chat_api_post = "http://devinxzhou.com:5000/api/v1.0/post"


async function ChatQuery(message){
    let send_data = {
        "message": message,
        "time": new Date(Date.now()).getHours() + ":" + new Date(Date.now()).getMinutes(),
    }
    
    const response = await fetch(chat_api_post, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        // credentials: "include", 
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(send_data),
    })
    return response
}
export default ChatQuery;
